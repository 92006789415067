import React, { Component } from 'react'
import * as queryString from 'query-string'
import { subscribeToData, fetchDataFromSanity } from '../helpers/sanity'
import Hero from '../components/preview/hero'
import Gallery from '../components/preview/gallery'

export class PreviewTemplate extends Component {
  state={
    isLoading: true,
    data: undefined
  }

  componentDidMount() {
    const
      query = queryString.parse(this.props.location.search),
      { projectId, isDraft } = query;

    this.fetchData();

    if (isDraft) {
      subscribeToData(projectId, this.fetchData);
    }

  }

  fetchData = async () => {
    const
      query = queryString.parse(this.props.location.search),
      { projectId, isDraft } = query;

    fetchDataFromSanity(projectId, isDraft)
      .then(res => this.setState({
        isLoading: false,
        data: res[0]
      }))
      .catch(() => this.setState({isLoading:false}))

  };

  render() {
    const
      {isLoading, data} = this.state;

    if (isLoading && !data) {

      return (
        <div id="success-container">
          <h1>LOADING</h1>
          <p>Please, wait...</p>
        </div>
      )

    } else {
      const { title, title2, vimeoVideo, location, pictures, credits } = this.state.data
      return(
        <>
          <Hero title={title} title2={title2} video={vimeoVideo.oEmbedData.html} location={location} />
          <Gallery title={title} title2={title2} pictures={pictures} credits={credits} />
        </>
      )
    }
  }
}

export default PreviewTemplate
