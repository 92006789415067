import React from 'react'
import Image from '../image'
import { builder } from '../../helpers/sanity'

const Gallery = ({ pictures, credits, title, title2 }) => {
  const getPictures = pictures.gallery.slice(0, 13)

  const getSpeed = (i) => {
    switch (i) {
      case 0:
      case 1:
      case 3:
      case 6:
      case 9:
      case 12:
        return "1"
      case 2:
      case 7:
      case 10:
        return "2"
      case 4:
      case 8:
      case 11:
        return "3"
      default:
        return "4"
    }
  }

  const urlFor = (source) => {
    return builder.image(source)
  }

  return(
    <div className="project-gallery grid" data-scroll-section>
      {pictures && getPictures.map((picture, i) => {
        return (
          <div key={i} data-scroll data-scroll-speed={getSpeed(i)} className="picture">
            <Image fluid={{src: urlFor(picture.asset).url()}} alt={`${title} ${title2} | Gutiérrez F Studio`}/>
          </div>
        )
      })}
      {credits && credits.length !== 0 && (
        <div className="credits" style={{marginBottom:128}} data-scroll data-scroll-speed="1">
          <p>PHOTOGRAPHY:
            {credits.map((credit, i) => {
              return(
                <a key={i} href={credit.url} target="__blank"> {credit.name}{credits.length > 1 && (credit !== credits[credits.length - 1]) ? ',' : ''}</a>
              )
            })}
          </p>
        </div>
      )}
    </div>
  )
}

export default Gallery
